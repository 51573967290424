import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

import dataProvider from 'dataProvider';
import { useEmployee } from 'employeeContext';

import RepairmanStockStyles from 'pages/RepairmanStock/RepairmanStockStyles';
import SparePartDefectsDeclaration from 'pages/SparePartDefectsDeclaration';
import SkuList from 'pages/Store/components/SkuList';

import SkuInventory from 'components/SkuInventory/SkuInventory';
import SparePartDetails from 'components/SparePartDetails';
import SpeedDial from 'components/SpeedDial/SpeedDial';
import SpeedDialAction from 'components/SpeedDial/SpeedDialAction';
import SpeedDialIcon from 'components/SpeedDial/SpeedDialIcon';

import { SkuLocation } from 'types/sku-location';
import { SparePart } from 'types/spare-part';

import { useSpareParts } from 'utils/api/api';

import SparePartTransfer from './SparePartTransfer/SparePartTransfer';
import StockListFilter from './StockListFilter';

const styleList = makeStyles(RepairmanStockStyles);

const RepairmanStockVisualization: React.FunctionComponent = () => {
  const classes = styleList();
  const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false);
  const [isTransferSparePartModalOpen, setIsTransferSparePartModalOpen] = useState<boolean>(false);
  const [isSparPartDetailsOpened, setIsSparePartDetailsOpen] = useState<boolean>(false);
  const [selectedSparePart, setSelectedSparePart] = useState<SparePart>();
  const [isInventoryOpen, setIsInventoryOpen] = useState<boolean>(false);
  const [location, setLocation] = useState<SkuLocation>();
  const [isSparePartDefectOpen, setIsSparePartDefectOpen] = useState<boolean>(false);

  const employee = useEmployee().employee;

  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };

  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(true);
  };

  const handleOpenSparePartDetails = (record: SparePart) => {
    setIsSparePartDetailsOpen(true);
    setSelectedSparePart(record);
  };

  const handleCloseSparePartDetails = () => {
    setIsSparePartDetailsOpen(false);
    setSelectedSparePart(undefined);
  };

  const handleCloseInventory = () => {
    setIsInventoryOpen(false);
  };

  const { spareParts } = useSpareParts({ enable_employee_filter: 'on', status: 'repairman_stock' });

  const actions = [
    {
      icon: <ImportExportIcon />,
      tooltipTitle: 'Transfert',
      onClick: () => setIsTransferSparePartModalOpen(true),
      tooltipOpen: true,
    },
    {
      icon: <YoutubeSearchedForIcon />,
      tooltipTitle: 'Inventaire (à venir)',
      onClick: () => setIsInventoryOpen(true),
      tooltipOpen: true,
    },
    {
      icon: <ImportExportIcon />,
      tooltipTitle: 'Déclarer une pièce défectueuse',
      onClick: () => setIsSparePartDefectOpen(true),
      tooltipOpen: true,
    },
  ];

  const speedDialProps = {
    ariaLabel: 'repairmanMenu',
    icon: <SpeedDialIcon />,
    onClose: handleCloseSpeedDial,
    onOpen: handleOpenSpeedDial,
    open: openSpeedDial,
    className: `${classes.speedDial} ${classes.speedDialPage1}`,
  };

  useEffect(() => {
    dataProvider.getOne('my-car', { employee: employee }).then(({ data: location }) => {
      if (location && location.id) {
        setLocation(location);
      }
    });
  }, [employee]);

  return (
    <>
      <div className={classes.RepairmanStokReceptionContainer}>
        <SkuList
          filter={{ enable_employee_filter: 'on', status: 'repairman_stock' }}
          filters={<StockListFilter />}
          handleSkuSelection={handleOpenSparePartDetails}
          showBooking
          showOnboardedStock
          showBroken
        />
      </div>
      <SparePartTransfer
        open={isTransferSparePartModalOpen}
        onClose={() => setIsTransferSparePartModalOpen(false)}
      />
      {selectedSparePart && (
        <SparePartDetails
          open={isSparPartDetailsOpened}
          onClose={handleCloseSparePartDetails}
          selectedSparePart={selectedSparePart}
        />
      )}
      {location && isInventoryOpen && (
        <SkuInventory
          location={location}
          open={isInventoryOpen}
          onClose={handleCloseInventory}
          skuList={spareParts}
          skuFilter={{
            enable_employee_filter: 'on',
            status: 'repairman_stock',
          }}
          redirectUrl="/calendar"
          ConfirmationButtonText="Retourner à l’agenda"
        />
      )}
      {isSparePartDefectOpen && (
        <SparePartDefectsDeclaration handleClose={() => setIsSparePartDefectOpen(false)} />
      )}

      <SpeedDial {...speedDialProps}>
        {actions.map((action) => (
          <SpeedDialAction key={action.tooltipTitle} {...action} />
        ))}
      </SpeedDial>
    </>
  );
};

export default RepairmanStockVisualization;
