import React, { useState } from 'react';

import SkuInventory from 'components/SkuInventory/SkuInventory';
import SkuLocationSelectionPortal from 'components/SkuLocationSelectionPortal';

import { SkuLocation } from 'types/sku-location';

import { useSpareParts } from 'utils/api/api';

type WorkshopSparePartInventoryProps = {
  handleCloseInventory: () => void;
};

const WorkshopSparePartInventory: React.FunctionComponent<WorkshopSparePartInventoryProps> = ({
  handleCloseInventory,
}: WorkshopSparePartInventoryProps) => {
  const [isSkuLocationSelectionOpen, setIsSkuLocationSelectionOpen] = useState<boolean>(true);
  const [sparePartLocation, setSparePartLocation] = React.useState<SkuLocation | null>(null);
  return (
    <>
      {isSkuLocationSelectionOpen && (
        <SkuLocationSelectionPortal
          isOpen={isSkuLocationSelectionOpen}
          handleClosePortal={handleCloseInventory}
          handleOpenPortal={() => setIsSkuLocationSelectionOpen(true)}
          title={'Sélectionner un emplacement'}
          onConfirm={(location: SkuLocation) => {
            setSparePartLocation(location);
            setIsSkuLocationSelectionOpen(false);
          }}
        />
      )}
      {sparePartLocation && (
        <SparePartInventory
          sparePartLocation={sparePartLocation}
          setSparePartLocation={setSparePartLocation}
          setIsSkuLocationSelectionOpen={setIsSkuLocationSelectionOpen}
        />
      )}
    </>
  );
};

type SparePartInventoryProps = {
  sparePartLocation: SkuLocation;
  setSparePartLocation: (value: null) => void;
  setIsSkuLocationSelectionOpen: (isOpen: boolean) => void;
};
const SparePartInventory: React.FunctionComponent<SparePartInventoryProps> = ({
  sparePartLocation,
  setSparePartLocation,
  setIsSkuLocationSelectionOpen,
}: SparePartInventoryProps) => {
  const { spareParts } = useSpareParts({
    location_id: sparePartLocation.id,
  });

  return (
    <SkuInventory
      location={sparePartLocation}
      open={true}
      onClose={() => {
        setSparePartLocation(null);
        setIsSkuLocationSelectionOpen(true);
      }}
      skuList={spareParts}
      skuFilter={{}}
      redirectUrl="/recondition"
      ConfirmationButtonText="Terminer"
      isCreationAllowed
      SkuCreationStatus="instock"
    />
  );
};
export default WorkshopSparePartInventory;
